import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const ReferralPolicyModal = ({ showReferralPolicyModal, setShowReferralPolicyModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  function hideModalOperation() {
    setShowReferralPolicyModal(false)
  }

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("referral-policy", msisdn);
    analyticEvent('referral-policy', 'referral-policy-view')
  }, [])
  return (
    <>
      <Modal show={showReferralPolicyModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>রেফারাল পলিসি</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block',overflowX:'hidden' }}>
          <>
          <p> <b>রেফারাল এর নিয়মঃ</b><br />
            <br /> ১। এক জন ইউজার একাধিক জনকে রেফার করতে পারবেন ।
            <br /> ২। নতুন ইউজার শুধুমাত্র সাবস্ক্রিপশন এর সময় রেফারাল কোড ব্যবহার করতে পারবেন।
            <br /> ৩। একজন ইউজার শুধুমাত্র একবার রেফারাল কোড ব্যবহার করতে পারবেন।
            </p>

            <p> <b> রেফারাল এর সুবিধাঃ</b><br />
            <br /> ১। রেফার কোড ব্যবহার করে সাবস্ক্রিপশন করলে , যে রেফার করেছে এবং যে রেফার কোড ব্যবহার করেছে উভয়ই ৫ টি রেফারাল স্কোর পাবেন । 
            <br /> ২। প্রতিটি ৫ রেফারাল স্কোর এর জন্য ইউজার চলমান সপ্তাহের উইনার লিস্ট এ অতিরিক্ত ৫ স্কোর বেশি পাবেন 
            </p>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ReferralPolicyModal