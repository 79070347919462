import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "../../assets/css/modal.css";
import "./LoginModal.css";
import axios from "axios";
import { baseUrl, portal, dataContext } from "../../App";
import activityLogSubmission from "../../helper/activitylog";
import { useNavigate, useSearchParams } from "react-router-dom";
import analyticEvent from "../../helper/gaEvent";

const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  activeBottom,
  setActiveBottom,
  setShowOtpModal
}) => {
  const { token, setToken, playerData, setPlayerData } =
    useContext(dataContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  useEffect(() => {
    analyticEvent('login', 'login-view')
  }, [])
  if (activeBottom === "campaign") {
    localStorage.setItem("AuthToken", "");
    localStorage.setItem("user_msisdn", "");
  }
  function checkMobileNumber() {
    var mobilePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (mobileNumber.match(mobilePattern)) {
      return true;
    } else {
      return false;
    }
  }
  function loginOperation() {
    if (checkMobileNumber()) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/api/login/`, {
          msisdn: mobileNumber.toString().slice(-10),
          portal: portal,
          qcid: sessionStorage.getItem("cid")
        })
        .then((response) => {
          let data = response.data;
          let newToken = response.data.token;
          console.log(data,data?.is_verified);
          if((data?.is_verified === false)){
            localStorage.setItem("OtpAuthToken", newToken);
            setShowOtpModal(true);
          }else{
            localStorage.setItem("AuthToken", newToken);
            setToken(newToken);
          }
          localStorage.setItem("user_msisdn", mobileNumber.toString().slice(-10));
            activityLogSubmission(
            "login_submit",
            mobileNumber.toString().slice(-10)
          );
          analyticEvent('login', 'login-success')
          if (activeBottom === "campaign") {
            if(response.data.isSubscribe){
              navigate("../");
            }else{
              activityLogSubmission(
                "msisdn_submit",
                mobileNumber.toString().slice(-10)
              );
              // navigate("../");
              const paramsObject = {};

              for (const [key, value] of searchParams) {
                paramsObject[key] = value;
              }
              
              console.log(paramsObject);
              const config = {
                headers: { Authorization: `Token ${newToken}` },
              };
              axios
              .post(
                `${baseUrl}/subscription/create/`,
                { portal: portal, ...paramsObject },
                config
              )
              .then((response) => {
                let data = response.data;
                setShowLoginModal(false);
                setIsLoading(false);
                console.log(data);
                if (data?.redirectURL) {
                  console.log(`${data?.redirectURL}`);
                  window.location.replace(`${data?.redirectURL}`);
                }
              });
            }
          }else{
            setShowLoginModal(false);
            setIsLoading(false);
          }
        });
    } else {
      console.log("error");
      setShowErrorOutline(true);
      analyticEvent('login', 'login-error')
    }
  }

  function hideModalOperation() {
    setShowLoginModal(false);
    if (activeBottom === "profile") {
      if (!playerData?.id) {
        setActiveBottom("home");
      }
    }
    if (activeBottom === "campaign") {
      navigate("../");
    }
  }
  return (
    <>
      <Modal
        show={showLoginModal}
        centered
        dialogClassName="modal-dialog"
        contentClassName="modalContent"
        onHide={hideModalOperation}
      >
        <Modal.Header closeButton>
          <Modal.Title>আপনার মোবাইল নম্বর দিন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className={`inputSpan ${showErrorOutline && "errorOutline"}`}>
            +88{" "}
            <input
              type="tel"
              maxLength={11}
              onKeyUp={(e) => {
                setMobileNumber(e.target.value);
                setShowErrorOutline(false);
              }}
              onKeyDown={(e) => e.key === 'Enter' && loginOperation()}
              id="mobileNumber"
              name="mobileNumber"
              placeholder="মোবাইল নম্বর"
              className="inputFieldMobile"
            />{" "}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn numberSubmit"
            onClick={loginOperation}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "প্রবেশ করুন"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginModal;
