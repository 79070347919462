import React, { useContext } from 'react'
import './Sidebar.css'
import { motion } from "framer-motion"
import sidebarClose from '../../assets/img/v2/x.png'
import smallAvatar from '../../assets/img/leaderboard/avatarSmall.png'
import { baseUrl, dataContext } from '../../App'
import axios from "axios";

const Sidebar = ({ props }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const { showSidebar, setShowSidebar, showLoginModal, setShowLoginModal, activeBottom, setActiveBottom, showContactModal, setShowContactModal, showTCModal, setShowTCModal, showReferralPolicyModal, setShowReferralPolicyModal,
    showRefundModal, setShowRefundModal, showRewardsModal, setShowRewardsModal } = props;

  function logoutOperation() {
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    let lastToken = token;
    setToken(null)
    sessionStorage.setItem('AuthToken', '')
    localStorage.setItem("user_msisdn", "");
    setPlayerData({})
    setShowSidebar(false);
    console.log(config)
    setActiveBottom("home");
    axios
      .post(
        `${baseUrl}/api/logout/`, {
        empty: ''
      },
        {
          headers: { Authorization: `Token ${lastToken}` },
        }
      )
      .then((response) => { console.log(response); })
  }
  const variants = {
    slideIn: { x: '100%', opacity: 1, transition: { duration: 0.3 } },
    slideOut: { x: '0%', opacity: 0, transition: { duration: .3 } }
  };


  return (
    <>
      <motion.div className="ppm_side_bar"
        variants={variants}
        animate={showSidebar ? 'slideIn' : 'slideOut'}
      >
        <img src={sidebarClose} onClick={() => { setShowSidebar(false) }} className="sidebarClose" alt="" srcSet="" />
        <div className="headerPart">
          {playerData?.id && (<>
            {/* <img src="./assets/img/leaderboard/avatarSmall.png" className="detailAvatar" alt="" srcSet="" /> */}
            <img src={(playerData?.avatar_img) ? `${baseUrl}${playerData?.avatar_img}` : require(`../../assets/avatar/avatar${(playerData?.avatar_id) ? playerData.avatar_id : 1}.png`)} className="sidebarAvatar" alt="" srcSet="" />
            <div className="detailBox">
              <div className="detailName">{(playerData?.name) ? playerData.name : `Player-${(playerData?.id) ? playerData.id : ''}`}</div>
              <div className="detailMobile">{(playerData?.id) ? `0${(playerData.msisdn).toString().slice(-10)}` : "-"}</div>
            </div></>)}
          {(!playerData?.id) ? (<button className="loginBtn" onClick={() => { setShowLoginModal(true); setShowSidebar(false) }}>Login</button>) : ''}
        </div>
        <div className="menuPart">
          <div className="singleMenu" onClick={() => { setActiveBottom('home'); setShowSidebar(false) }}><i className="fa fa-home fa-fw"></i>&nbsp; হোম</div>
          <div className="singleMenu" onClick={() => {
            setShowSidebar(false);
            if (playerData?.id) { setActiveBottom('profile') } else { setShowLoginModal(true); }
          }
          }><i className="fa fa-user  fa-fw"></i>&nbsp;  প্রোফাইল</div>
          <div className="singleMenu" onClick={() => { setShowRewardsModal(true); setShowSidebar(false) }}><i className="fa-solid fa-medal"></i>&nbsp; প্রাইজ</div>
          <div className="singleMenu" onClick={() => {  setActiveBottom('referral'); setShowSidebar(false); }}><i className="fas fa-users"></i>&nbsp; রেফার
          </div>
          <div className="singleMenu" onClick={() => { setShowContactModal(true); setShowSidebar(false) }}><i className="fa-solid fa-headset"></i>&nbsp;
            হেল্প</div>
          <div className="singleMenu" onClick={() => { setShowTCModal(true); setShowSidebar(false) }}><i className="fas fa-cog"></i>&nbsp;
            শর্তাবলী</div>
        </div>
        {(playerData?.id) ? (<div className="sideBarLogout">
          <div className="logoutBtn" onClick={() => { logoutOperation() }}><i className="fa-solid fa-right-from-bracket fa-fw"></i>Logout</div>
        </div>) : ''}
      </motion.div>
      {showSidebar && (<div className="ppm_close_bar " onClick={() => { setShowSidebar(false) }}></div>)}
    </>
  )
}

export default Sidebar