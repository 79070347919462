import React from 'react'
import styles from './categoryBox.module.css'
import bangladeshImg from './bangla1.png'
import randomImg from './random1.png'
import sportsImg from './sports1.png'
import internationalImg from './international1.png'
import gkImg from './gk1.png'
const CategoryBox = ({ props }) => {
  const { events, quizClickHandler } = props;
  const randomEvents = [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  function randomRange(min, max) { return Math.floor(Math.random() * (max - min + 1)) + min; }
  return (
    <div style={{ zIndex: '77' }}>
      <div className={`${styles.container}`}>
        <div className={`${styles.element} ${styles.column1}`}>
          <img src={randomImg} alt="" srcSet="" style={{ width: '100%', height: '100%' }} draggable="false" onClick={() => {
            let randomQuiz = (randomRange(0, (randomEvents.length -1)))
            console.log(randomQuiz)
            quizClickHandler(76)
          }} />
        </div>
        <div className={`${styles.element} ${styles.column5}`}>
          <img src={sportsImg} alt="" srcSet="" style={{ width: '100%', height: '100%' }} draggable="false" onClick={() => quizClickHandler(43)} />
        </div>
        <div className={`${styles.element} ${styles.column3}`}>
          <img src={internationalImg} alt="" srcSet="" style={{ width: '100%', height: '100%' }} draggable="false" onClick={() => quizClickHandler(42)} />
        </div>
        <div className={`${styles.element} ${styles.column4}`}>
          <img src={bangladeshImg} alt="" srcSet="" style={{ width: '100%', height: '100%' }} draggable="false" onClick={() => quizClickHandler(44)} />
        </div>
        <div className={`${styles.element} ${styles.column2}`}>
          <img src={gkImg} alt="" srcSet="" style={{ width: '100%', height: '100%' }} draggable="false" onClick={() => quizClickHandler(46)} />
        </div>
      </div>
    </div>
  )
}

export default CategoryBox