import React from 'react'

const TimerBoxLinear = ({timerWidth,outerBorderColor='#fff',innerColor='#ff99ff'}) => {
  let timerStyle = {
    outerDiv: {
      width: '100%',
      height: '100%',
      borderRadius:'10px',
      overflow:'hidden',
      border: `1px solid ${outerBorderColor}`,
    },
    timerBox:{
      width: '100%',
      height: '100%',
      background:innerColor,
      borderRadius:'10px',
    }
  }

  // let timerWidth = 50;
  return (
    <>
      {/* <div className="timerBox text-white text-center mt-1">
        <div className="totalTimerTop" style={{ width: `${timerWidth}%` }}></div>
      </div> */}
      <div style={timerStyle.outerDiv}>
      <div style={{...timerStyle.timerBox,width: `${timerWidth}%`}}>
      </div>
      </div>
    </>
  )
}

export default TimerBoxLinear