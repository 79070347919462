import React, { useEffect, useState } from 'react'


const SingleOptionRamadan = ({ props }) => {
  const [allOptions, setAllOptions] = useState([])
  const { currentQues, handleAnswerClick,styles } = props;
  useEffect(() => {
    const list = [];
    list.push(<div className={` ${styles.singleOptions} op-1`} onClick={(e) => handleAnswerClick(e, 1)}>
    <div className={`Option1 ${styles.op}`}>{currentQues?.option1}</div>
  </div>)
    list.push(<div className={` ${styles.singleOptions} op-2`} onClick={(e) => handleAnswerClick(e, 2)}>
    <div className={`Option2 ${styles.op}`}>{currentQues?.option2}</div>
  </div>)
    if (currentQues?.options_nub >= 3) {
      list.push(<div className={` ${styles.singleOptions} op-3`} onClick={(e) => handleAnswerClick(e, 3)}>
      <div className={`Option3 ${styles.op}`}>{currentQues?.option3}</div>
    </div>)
    }
    if (currentQues?.options_nub >= 4) {
      list.push(<div className={` ${styles.singleOptions} op-4`} onClick={(e) => handleAnswerClick(e, 4)}>
      <div className={`Option4 ${styles.op}`}>{currentQues?.option4}</div>
    </div>)
    }
    list.sort(() => Math.random() - 0.5);
    setAllOptions(list)
  }, [currentQues])

  return (
    <>
      {allOptions.map((item,index) => {
        return (
          <React.Fragment key={index}>{item}</React.Fragment>
        )
      })}
    </>
  )
}

export default SingleOptionRamadan