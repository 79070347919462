import React from 'react'

const TimerBoxLinearBottom = ({ timerWidth, bgColor }) => {
  let timerStyle = {
    outerDiv: {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      overflow: 'hidden',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    timerBox: {
      width: '100%',
      height: '100%',
      background: bgColor || '#CA0000',
      borderRadius: '10px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }
  }

  // let timerWidth = 50;
  return (
    <>
      {/* <div className="timerBox text-white text-center mt-1">
        <div className="totalTimerTop" style={{ width: `${timerWidth}%` }}></div>
      </div> */}
      <div style={timerStyle.outerDiv}>
        <div style={{ ...timerStyle.timerBox, width: `${timerWidth}%` }}>
        </div>
      </div>
    </>
  )
}

export default TimerBoxLinearBottom