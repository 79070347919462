import React from 'react'
import './Navbar.css';
import menuImg from '../../assets/img/v2/menu.png'
// import logoImg from '../../assets/logo/logo_wind_v2.png'
import logoImg from '../../assets/logo/logo_v4.png'
const Navbar = ({showSidebar,setShowSidebar}) => {
  
  return (
    <div className="ppm_navbar">
    <img src={menuImg} className="toggleBtn" onClick={()=>{setShowSidebar(true)}} alt="" srcSet=""/>
    <span className='ppm_quizard_text'>কুইজার্ড</span>
    <img src={logoImg} className="ppm_logo" alt="" srcSet=""/>
  </div>
  )
}

export default Navbar