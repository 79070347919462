import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useElapsedTime } from 'use-elapsed-time'
import { baseUrl, dataContext, portal } from '../../../App'
import rightAudio from '../../../assets/sound/right.wav'
import timerAudio from '../../../assets/sound/timer.mp3'
import timesUpAudio from '../../../assets/sound/timesUp.mp3'
import wrongAudio from '../../../assets/sound/wrong2.mp3'
import TimerBoxLinear from '../TimerBoxLinear/TimerBoxLinear'
import styles from './QuestionBodySummer.module.css'
import mangoBg from './assets/mango.jpg'
import jackBg from './assets/jack.jpg'
import summerBg from './assets/summerbg.png'
import { colorCleanUp, secondsToMS, translateToBanglaNum } from './questionHelper'

import activityLogSubmission from '../../../helper/activitylog'
import SingleOption from './SingleOption'


const QuestionBodySummer = ({ eventDetails, finalScore, setFinalScore, setShowResultModal, playerRound }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  // const [timerTick, setTimerTick] = useState(new Audio(timerAudio)) 
  const [allQuestions, setAllQuestions] = useState([])
  const [currentQues, setCurrentQues] = useState({})
  const [currentQuesIndex, setCurrentQuesIndex] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const [totalWrongAnswer, setTotalWrongAnswer] = useState(0)
  const [currentBackground, setCurrentBackground] = useState(summerBg)
  const [timerWidth, setTimerWidth] = useState(100)
  const [progressWidth, setProgressWidth] = useState(0)
  const [singleTimerWidth, setsingleTimerWidth] = useState(100)
  const [timerTime, setTimerTime] = useState(100)
  const [singleTime, setSingleTime] = useState(6)
  const [isPlaying, setIsPlaying] = useState(false);
  const { elapsedTime } = useElapsedTime({ isPlaying });

  const [singleTimerLower, setSingleTimerLower] = useState(0.0)

  const tickingElm = useRef(0);
  const currentQuestionPosition = useRef(0);
  const repeatAfter = 5;
  let arrOfBg = [summerBg, mangoBg, jackBg];
  useEffect(() => {
    axios.get(`${baseUrl}/api/questions/?rules=${eventDetails.id}&portal=${portal}`, {
      headers: { Authorization: `Token ${token}` }
    })
      .then(response => {
        setAllQuestions(response.data)
        setIsLoadingEnd(true)
        setCurrentQuesIndex(0)
        let totalTime = (response.data).reduce(function (accumulator, currentValue) {
          // console.log(currentValue)
          return accumulator + (currentValue?.time_allocated ? currentValue.time_allocated : 6);
        }, 0);
        // console.log(totalTime)
        setTimerTime(totalTime)
        // insertData(0, playerData.msisdn, playerRound + 1, 1, 'initial', 'general', eventDetails.id)
        tickingElm.current = new Audio(timerAudio)
        tickingElm.current.play()
        tickingElm.current.loop = true;


      })
  }, [])


  useEffect(() => {
    setFinalScore(totalRightAnswer)
  }, [totalRightAnswer])

  // timerCode
  // let timerTime = eventDetails.allocated_time;
  let remainingWidth = (((timerTime - elapsedTime) / timerTime) * 100).toFixed(2);
  let remainingTime = (((timerTime - elapsedTime))).toFixed(2);

  useEffect(() => {
    setTimerWidth(remainingWidth)
  }, [remainingWidth])

  if (elapsedTime > timerTime) {
    if (isPlaying) {
      setIsPlaying(false)
      gameDone()
    }
  }

  // let singleTime = (tempTime / tempQuesLenght);
  let singleTimerUpper = singleTime + singleTimerLower;
  let singleTimerRemaining = (((singleTimerUpper - elapsedTime) / singleTime) * 100).toFixed(2);
  useEffect(() => {
    setsingleTimerWidth(singleTimerRemaining)
  }, [singleTimerRemaining])
  if (singleTimerUpper <= elapsedTime) {
    if (isPlaying && (!isSubmitted)) {
      setIsPlaying(false)
      new Audio(timesUpAudio).play();
      singleTimerSubmit()
    }
  }
  // Timer Done

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission(`events-${eventDetails.id}`, msisdn);
  }, [])

  // Loading: [Done]
  useEffect(() => {
    if (isLoadingEnd) {
      setCurrentQues(allQuestions[currentQuesIndex])
      setIsPlaying(true)
      setSingleTimerLower(parseFloat(elapsedTime.toFixed(1)))
      setSingleTime((allQuestions[currentQuesIndex]?.time_allocated) ? allQuestions[currentQuesIndex].time_allocated : 6)
      console.log(currentQuestionPosition.current)
      let position = Math.floor(currentQuestionPosition.current / repeatAfter)
      let arrPos = position % arrOfBg.length;
      setCurrentBackground(arrOfBg[arrPos])
      currentQuestionPosition.current = currentQuestionPosition.current + 1;
    }
    let progress = allQuestions.length ? ((currentQuesIndex) ? ((currentQuesIndex) / allQuestions.length) : 0) : 0;
    setProgressWidth((progress * 100).toFixed(2))
  }, [currentQuesIndex])

  useEffect(() => {
    return () => {
      tickingElm.current.pause()
      console.log("in cleanup")
    }
  }, [])

  function handleAnswerClick(event, answerId) {
    if (isSubmitted) return;
    setIsSubmitted(true)

    let isRight = 0;
    let mobile = playerData.msisdn;
    let round = playerRound + 1;
    let questionId = currentQues.id;
    let selectedAns = `Option${answerId}`;
    let category = currentQues.category;

    if (parseInt((currentQues.ans.trim()).slice(-1)) === answerId) {
      event.currentTarget.classList.add('bg_right')
      new Audio(rightAudio).play();
      setTotalRightAnswer((totalRightAnswer + 1))
      isRight = 1;
    } else {
      event.currentTarget.classList.add('bg_wrong')
      new Audio(wrongAudio).play()
      setTotalWrongAnswer((totalWrongAnswer + 1))
    }
    insertData(isRight, mobile, round, questionId, selectedAns, category, eventDetails.id);
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setTimeout(() => {
        setIsPlaying(false)
        colorCleanUp('singleOptions')
        setCurrentQuesIndex((currentQuesIndex + 1))
        setIsSubmitted(false)
      }, 400);
    } else {
      gameDone()
    }
  }

  function gameDone() {
    tickingElm.current.pause()
    setIsPlaying(false)
    setShowResultModal(true)
    setProgressWidth('100')
  }

  function singleTimerSubmit() {
    insertData(0, playerData.msisdn, playerRound + 1, currentQues.id, 'notSelected', 'general', eventDetails.id)
    setIsSubmitted(false)
    setTotalWrongAnswer((totalWrongAnswer + 1))
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setCurrentQuesIndex((currentQuesIndex + 1))
    } else {
      gameDone()
    }
  }

  function insertData(isRight, mobile, round, questionId, selectedAns, category, eventId) {
    // insert Data Function
    // console.log(isRight, mobile, round, questionId, selectedAns, category, eventId)
    axios.post(`${baseUrl}/api/participant/`, {
      is_right: isRight,
      msisdn: mobile,
      round_number: round,
      question: questionId,
      selected_answer: selectedAns,
      category: category,
      event: eventId
    }, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  return (
    <><div className={styles.quizPageBodySummer} style={{ backgroundImage: `url(${currentBackground})` }}>
      <div className="quiz_navbar">
      </div>
      <div className="newTimerBox">
        <div>সময়</div>
        <div>{translateToBanglaNum(secondsToMS(parseFloat((remainingTime < 0) ? '0' : remainingTime)))}</div>

      </div>


      <div className="progressBox text-white text-center mt-3 mb-2">
        <div className="progressTop" style={{ width: `${progressWidth}%` }}></div>
      </div>

      <div className="Score-div mt-2">
        <div className="correctAnswer fw-bold">
          <span className='text-white'>সঠিক :</span>
          <span className="correctAnswerValue mx-2" style={{ color: '#fff' }}>{translateToBanglaNum(totalRightAnswer.toString())}</span>
        </div>

        <div className="wrongAnswer text-danger fw-bold">
          <span className='text-white'>ভুল :</span>
          <span className="wrongAnswerValue mx-2" style={{ color: '#fff' }}>{translateToBanglaNum(totalWrongAnswer.toString())}</span>
        </div>
      </div>

      <div className="questionBox">

        <div className="questionFieldOuter">
          <div className="timerLinear">
            <TimerBoxLinear timerWidth={singleTimerWidth}></TimerBoxLinear>
          </div>
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="questionFieldWrapper">
            <div className="questionField">
              {currentQues?.question}
            </div>
          </div>
        </div>
        <div className="optionsWrapper">
          <div className="optionsField">
            <SingleOption props={{ currentQues, handleAnswerClick, currentQuesIndex }}></SingleOption>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default QuestionBodySummer