import React, { useContext, useEffect, useRef, useState } from 'react'
import './QuestionBodyRamadan.css'
import styles from './QuestionBodyRamadan.module.css';
import rightImg from '../../../assets/img/v2/right.png'
import wrongImg from '../../../assets/img/v2/wrong.png'
import scoreTimerImg from './assets/scoreboard.png'
import rightAudio from '../../../assets/sound/right.wav'
import wrongAudio from '../../../assets/sound/wrong2.mp3'
import timesUpAudio from '../../../assets/sound/timesUp.mp3'
import timerAudio from '../../../assets/sound/timer.mp3'
import axios from 'axios'
import { baseUrl, dataContext, portal } from '../../../App'
import { useElapsedTime } from 'use-elapsed-time'
import { colorCleanUp, secondsToMS, translateToBanglaNum } from '../QuestionBodyHelper/questionHelper'
import TimerBoxLinear from '../TimerBoxLinear/TimerBoxLinear'
import CircularTimer from '../CircularTimer/CircularTimer'
import ProgressBar from '../ProgressBar/ProgressBar'
import activityLogSubmission from '../../../helper/activitylog'
import TimerBoxLinearBottom from '../TimerBoxLinearBottom/TimerBoxLinearBottom';
import SingleOptionRamadan from './SingleOptionRamadan';


const QuestionBodyRamadan = ({ eventDetails, finalScore, setFinalScore, setShowResultModal, playerRound }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  // const [timerTick, setTimerTick] = useState(new Audio(timerAudio)) 
  const [allQuestions, setAllQuestions] = useState([])
  const [currentQues, setCurrentQues] = useState({})
  const [currentQuesIndex, setCurrentQuesIndex] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const [totalWrongAnswer, setTotalWrongAnswer] = useState(0)
  const [timerWidth, setTimerWidth] = useState(100)
  const [progressWidth, setProgressWidth] = useState(0)
  const [singleTimerWidth, setsingleTimerWidth] = useState(100)
  const [timerTime, setTimerTime] = useState(100)
  const [singleTime, setSingleTime] = useState(6)
  const [isPlaying, setIsPlaying] = useState(false);
  const { elapsedTime } = useElapsedTime({ isPlaying });

  const [singleTimerLower, setSingleTimerLower] = useState(0.0)

  const tickingElm = useRef(0);
  // let timerTime;
  // let timerElm =new Audio(timerAudio)
  // timerElm.load()
  // Fetch Questions: initial submission 
  useEffect(() => {
    axios.get(`${baseUrl}/api/questions/?rules=${eventDetails.id}&portal=${portal}`, {
      headers: { Authorization: `Token ${token}` }
    })
      .then(response => {
        setAllQuestions(response.data)
        setIsLoadingEnd(true)
        setCurrentQuesIndex(0)
        let totalTime = (response.data).reduce(function (accumulator, currentValue) {
          // console.log(currentValue)
          return accumulator + (currentValue?.time_allocated ? currentValue.time_allocated : 6);
        }, 0);
        // console.log(totalTime)
        setTimerTime(totalTime)
        // insertData(0, playerData.msisdn, playerRound + 1, 1, 'initial', 'general', eventDetails.id)
        tickingElm.current = new Audio(timerAudio)
        tickingElm.current.play()
        tickingElm.current.loop = true;

        // timerTick.load()
        // timerTick.play()
        // timerTick.loop = true
      })
  }, [])


  useEffect(() => {
    setFinalScore(totalRightAnswer)
  }, [totalRightAnswer])

  // timerCode
  // let timerTime = eventDetails.allocated_time;
  let remainingWidth = (((timerTime - elapsedTime) / timerTime) * 100).toFixed(2);
  let remainingTime = (((timerTime - elapsedTime))).toFixed(2);

  useEffect(() => {
    setTimerWidth(remainingWidth)
  }, [remainingWidth])

  if (elapsedTime > timerTime) {
    if (isPlaying) {
      setIsPlaying(false)
      gameDone()
    }
  }

  // singleTimer with event time / question no
  let tempTime = (eventDetails?.allocated_time) || 6;
  let tempQuesLenght = (allQuestions?.length) || 1;

  // let singleTime = (tempTime / tempQuesLenght);
  let singleTimerUpper = singleTime + singleTimerLower;
  let singleTimerRemaining = (((singleTimerUpper - elapsedTime) / singleTime) * 100).toFixed(2);
  useEffect(() => {
    setsingleTimerWidth(singleTimerRemaining)
  }, [singleTimerRemaining])
  if (singleTimerUpper <= elapsedTime) {
    if (isPlaying && (!isSubmitted)) {
      setIsPlaying(false)
      new Audio(timesUpAudio).play();
      singleTimerSubmit()
    }
  }
  // Timer Done

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission(`events-${eventDetails.id}`, msisdn);
  }, [])

  // Loading: [Done]
  useEffect(() => {
    if (isLoadingEnd) {
      setCurrentQues(allQuestions[currentQuesIndex])
      setIsPlaying(true)
      setSingleTimerLower(parseFloat(elapsedTime.toFixed(1)))
      // setSingleTime(allQuestions[currentQuesIndex].time_allocated)
      setSingleTime((allQuestions[currentQuesIndex]?.time_allocated) ? allQuestions[currentQuesIndex].time_allocated : 6)
    }

    let progress = allQuestions.length ? ((currentQuesIndex) ? ((currentQuesIndex) / allQuestions.length) : 0) : 0;
    setProgressWidth((102 - (progress * 100)).toFixed(2))
  }, [currentQuesIndex])

  useEffect(() => {
    return () => {
      tickingElm.current.pause()
      console.log("in cleanup")
    }
  }, [])

  // console.log(allQuestions)
  function handleAnswerClick(event, answerId) {
    if (isSubmitted) return;
    setIsSubmitted(true)

    let isRight = 0;
    let mobile = playerData.msisdn;
    let round = playerRound + 1;
    let questionId = currentQues.id;
    let selectedAns = `Option${answerId}`;
    let category = currentQues.category;

    if (parseInt((currentQues.ans.trim()).slice(-1)) === answerId) {
      event.currentTarget.classList.add('bg_right_ramadan')
      new Audio(rightAudio).play();
      setTotalRightAnswer((totalRightAnswer + 1))
      isRight = 1;
    } else {
      event.currentTarget.classList.add('bg_wrong_ramadan')
      new Audio(wrongAudio).play()
      setTotalWrongAnswer((totalWrongAnswer + 1))
    }
    insertData(isRight, mobile, round, questionId, selectedAns, category, eventDetails.id);
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setTimeout(() => {
        setIsPlaying(false)
        colorCleanUp(`${styles.singleOptions}`)
        setCurrentQuesIndex((currentQuesIndex + 1))
        setIsSubmitted(false)
      }, 400);
    } else {
      gameDone()
    }
  }

  function gameDone() {
    tickingElm.current.pause()
    setIsPlaying(false)
    setShowResultModal(true)
    setProgressWidth('0')
  }

  function singleTimerSubmit() {
    // console.log('singleSubmitted')
    insertData(0, playerData.msisdn, playerRound + 1, currentQues.id, 'notSelected', 'general', eventDetails.id)
    setIsSubmitted(false)
    setTotalWrongAnswer((totalWrongAnswer + 1))
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setCurrentQuesIndex((currentQuesIndex + 1))
    } else {
      gameDone()
    }
  }

  function insertData(isRight, mobile, round, questionId, selectedAns, category, eventId) {
    // insert Data Function
    // console.log(isRight, mobile, round, questionId, selectedAns, category, eventId)
    axios.post(`${baseUrl}/api/participant/`, {
      is_right: isRight,
      msisdn: mobile,
      round_number: round,
      question: questionId,
      selected_answer: selectedAns,
      category: category,
      event: eventId
    }, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  let progressSingleDivWidth = 0, remainingQuestion = 0;
  if (allQuestions?.length) {
    progressSingleDivWidth = (100 / (allQuestions.length))
    if (currentQuesIndex != null) {
      remainingQuestion = allQuestions.length - currentQuesIndex;
    }
  }
  let arrOfRemainingBox = []
  for (let i = 0; i < remainingQuestion; i++) {
    arrOfRemainingBox.push(<div style={{ height: '100%', width: `${progressSingleDivWidth}%`, background: 'linear-gradient(90deg,#4EFF95 90%, #ffed4b00 90%)' }} className='ramadanBoxList'></div>);
  }

  return (
    <>
      <div className={`${styles.progressBox} text-white text-center mb-2`} style={{ display: 'flex' }}>
        {/* <div className={styles.progressTop} style={{ width: `${progressWidth}%` }}></div> */}
        {arrOfRemainingBox.map(singleBox => { return singleBox })}
      </div>

      {/* <div className="Score-div mt-4">
        <div className="correctAnswer fw-bold">
          <span className='text-black'>সঠিক :</span>
          <span className="correctAnswerValue mx-2" style={{ color: '#60930d' }}>{translateToBanglaNum(totalRightAnswer.toString())}</span>
        </div>

        <div className="wrongAnswer text-danger fw-bold">
          <span className='text-black' style={{ color: "#000" }}>ভুল :</span>
          <span className="wrongAnswerValue mx-2" style={{ color: '#ff6565' }}>{translateToBanglaNum(totalWrongAnswer.toString())}</span>
        </div>
      </div>

      <div className={styles.newTimerBox}>
        <div>সময়</div>
        <div>{translateToBanglaNum(secondsToMS(parseFloat((remainingTime < 0) ? '0' : remainingTime)))}</div>

      </div> */}
      <div className={styles.scoreAndTimer}>
        <img src={scoreTimerImg} alt="" srcSet="" className={styles.scoreAndTimerImg} />
        <div className={styles.timerDivInImage}>{translateToBanglaNum(secondsToMS(parseFloat((remainingTime < 0) ? '0' : remainingTime)))}</div>

        <div className={styles.scoreDiv}>
          <div className="correctAnswer fw-bold">
            <span className='text-white'>সঠিক :</span>
            <span className="correctAnswerValue mx-2" style={{ color: '#f5f5f5' }}>{translateToBanglaNum(totalRightAnswer.toString())}</span>
          </div>

          <div className="wrongAnswer text-danger fw-bold">
            <span className='text-white' style={{ color: "#f5f5f5" }}>ভুল :</span>
            <span className="wrongAnswerValue mx-2" style={{ color: '#f5f5f5' }}>{translateToBanglaNum(totalWrongAnswer.toString())}</span>
          </div>
        </div>
      </div>

      <div className={styles.questionField}>
        {currentQues?.question}
      </div>


      <div className={styles.questionBox}>

        <div className={styles.optionsWrapper}>
          <div className="optionsField">
            <SingleOptionRamadan props={{ currentQues, handleAnswerClick, currentQuesIndex, styles }}></SingleOptionRamadan>
          </div>
        </div>
      </div>
      <div className={styles.timerLinear}>
        <TimerBoxLinearBottom timerWidth={singleTimerWidth}></TimerBoxLinearBottom>
      </div>
    </>
  )
}

export default QuestionBodyRamadan