import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ReferralHistory.css'
import '../../../assets/css/modal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import axios from "axios";
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const ReferralHistory = ({ showReferralHistory, setShowReferralHistory, setShowLoginModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [totalRedeem, setTotalRedeem] = useState([]);


  function hideModalOperation() {
    setShowReferralHistory(false)
  }

  useEffect(() => {
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(
        `${baseUrl}/api/redeemedCode/redeemed-by/`,
        config
      ).then(res => {
        console.log(res?.data)
        // let redeem = res?.data?.redeemed_by_others[0]?.redeemed_by || [];
        // let redeem2 = res?.data?.redeemed_by_me?.redeemed_by || [];
        // let total = redeem.concat(redeem2)
        // if(total.length){
        // }
        setTotalRedeem(res?.data)
      })
      .catch(res => {
        console.log(res)
        localStorage.setItem("AuthToken", "");
        localStorage.setItem("user_msisdn", "");
        setPlayerData({});
        setShowLoginModal(true);
        setShowReferralHistory(false)
        setToken(null);
      });
  }, [])


  return (
    <>
      <Modal show={showReferralHistory} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton>
          <Modal.Title>Referral History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-striped">
            <table className="table" style={{ color: '#461081' }}>
              <thead>
                <tr>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  totalRedeem.reverse().map((singleRedeem, index) => {
                    { console.log(singleRedeem) }
                    return (<tr key={index}>
                      <td>0{singleRedeem?.username}</td>
                      <td>{singleRedeem?.created_at.slice(0, 10)}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ReferralHistory