import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import '../../../assets/css/modal.css'
import './TCModalTournament.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import tcImage from './tc.png'

const TCModalTournament = ({ showTCModalTournament, setShowTCModalTournament }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  
  function hideModalOperation() {
    setShowTCModalTournament(false)
  }

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("termsConditions", msisdn);
    analyticEvent('termsConditions', 'termsConditions-view')
  }, [])

  return (
    <>
      <Modal show={showTCModalTournament} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>শর্তাবলী</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", maxHeight: '525px', overflow: "scroll", display: 'block', overflowX: 'hidden' }}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>টুর্নামেন্ট সাবস্ক্রিপশন</Accordion.Header>
              <Accordion.Body>
                ৩০ টাকা / প্রতি সপ্তাহে
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>পেমেন্ট করার নিয়ম</Accordion.Header>
              <Accordion.Body>
                আপনার বিকাশ অ্যাকাউন্ট থাকতে হবে । বিকাশ পেমেন্ট এর মাধ্যমে QUIZARD সাবস্ক্রিপশন সম্পন্ন করতে পারবেন ।
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>খেলার নিয়ম</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>প্রতিদিন টুর্নামেন্ট কুইজে স্পোর্টস কুইজ অথবা উইন্টার কুইজ খেলতে হবে ।</li>
                  <li>সর্বোচ্চ ৩০ টি পছন্দের প্রশ্ন থাকবে এবং দৈনিক ২ বার করে খেলা যাবে ।</li>
                  <li>প্রতি রাউন্ড এ অংশগ্রহণকারীদের প্রদত্ত বিকল্পগুলি থেকে সঠিক উত্তর নির্বাচন করতে হবে।</li>
                  <li>অংশগ্রহণকারীরা প্রতিটি সঠিক উত্তরের জন্য ১ পয়েন্ট অর্জন করবে।</li>
                  <li>প্রতিটি প্রশ্নের উত্তর জমা দেওয়ার জন্য একটি নির্দিষ্ট সময়সীমা থাকবে।</li>
                  <li>দুটি রাউন্ড এর মধ্যে যে রাউন্ড এর স্কোর বেশি হবে, সেটিই ঐ দিনের ফাইনাল স্কোর হিসেবে গণনা করা হবে ।</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>স্মার্টফোন বিজয়ী হবার নিয়মাবলী</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>প্রতিটি টুর্নামেন্ট শেষে অংশগ্রহণকারীদের টোটাল স্কোর ও সময় গণনা করা হবে ।</li>
                  <li>পর পর চার সপ্তাহ আপনার সপ্তাহিক চার্জ সফল হতে হবে ।</li>
                  <li>সর্বনিন্ম ৮০% স্কোর করতে হবে ।</li>
                  <li>টুর্নামেন্ট চলাকালীন সময় QUIZARD থেকে আনসাবস্ক্রাইব করতে পারবে না ।</li>
                  <li>প্রতিটি টুর্নামেন্ট শেষে সেরা ৩ জন পর্যন্ত পাবেন মেগা পুরস্কার - স্মার্টফোন, স্মার্টওয়াচ সহ অসংখ্য গ্যাজেট ।</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>সাপ্তাহিক পুরস্কার</Accordion.Header>
              <Accordion.Body>
                <p>এছাড়া সপ্তাহে সেরা ৩ জন পাবেন ১০০০ টাকার ক্যাশ ইন ।</p>
                <ul>
                  <li>প্রথম পুরস্কার : ৫০০ টাকা</li>
                  <li>দ্বিতীয় পুরস্কার : ৩০০ টাকা</li>
                  <li>তৃতীয় পুরস্কার : ২০০ টাকা</li>
                </ul>

                <p>** সময়সীমা - চার সপ্তাহ ।</p>
                <p>** আয়োজক সত্তা তার বিবেচনার ভিত্তিতে পুরস্কার পরিবর্তন বা প্রতিস্থাপন করার অধিকার সংরক্ষণ করে ।</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>বিশেষ দ্রষ্টব্য</Accordion.Header>
              <Accordion.Body>
                <p>** সময়সীমা - চার সপ্তাহ ।</p>
                <p>** আয়োজক সত্তা তার বিবেচনার ভিত্তিতে পুরস্কার পরিবর্তন বা প্রতিস্থাপন করার অধিকার সংরক্ষণ করে ।</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>আনসাবস্ক্রাইব করার নিয়ম</Accordion.Header>
              <Accordion.Body>
                মেন্যু বার থেকে Terms & Conditions এ যেয়ে আনসাবস্ক্রাইব অপশন ক্লিক করে আনসাবস্ক্রিপশন করতে হবে ।
                <img src={tcImage} alt="Terms and Conditions" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TCModalTournament