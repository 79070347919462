import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import '../../assets/css/modal.css'
import './ProfileEditModal.css'
import axios from 'axios';
import { baseUrl, dataContext, portal } from '../../App';
import avatar1 from '../../assets/avatar/avatar1.png'
import avatar2 from '../../assets/avatar/avatar2.png'
import avatar3 from '../../assets/avatar/avatar3.png'
import avatar4 from '../../assets/avatar/avatar4.png'
import avatar5 from '../../assets/avatar/avatar5.png'
import avatar6 from '../../assets/avatar/avatar6.png'
import avatar7 from '../../assets/avatar/avatar7.png'
import avatar8 from '../../assets/avatar/avatar8.png'
import avatar9 from '../../assets/avatar/avatar9.png'
import avatar10 from '../../assets/avatar/avatar10.png'
import ImageUploading from 'react-images-uploading';
import activityLogSubmission from '../../helper/activitylog';
import analyticEvent from '../../helper/gaEvent';


const ProfileEditModal = ({ showProfileEditModal, setShowProfileEditModal }) => {
  const {token, setToken, playerData, setPlayerData, events, setEvents} = useContext(dataContext);
  const [nameValue, setNameValue] = useState((playerData?.name) || '');
  const [activePart, setActivePart] = useState((playerData?.avatar_img)?'img':'avatar');
  const [avatarId, setAvatarId] = useState((playerData?.avatar_id) || 1);
  const [images, setImages] = useState([]);

  function hideModalOperation() {
    setShowProfileEditModal(false)
  }
  function updateOperation() {
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    const formData = new FormData();
    // console.log(images['file'] || null)
    if(images[0]?.file){
      formData.append("avatar_img", images[0]?.file || null);
    }
    // formData.append("imgFile", file);
    formData.append("name", nameValue || '');
    formData.append("avatar_id", avatarId);
    axios.post(`${baseUrl}/api/participantProfileEdit/?portal=${portal}`, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data"
      }
    }).then(response => {
      // console.log(response)
      if (response.status === 200) {
        let newPlayerData = { ...playerData }
        newPlayerData.name = response.data.name;
        newPlayerData.avatar_id = response.data.avatar_id;
        newPlayerData.avatar_img = (response.data?.avatar_img)?`${response.data.avatar_img}`:'';
        setPlayerData(newPlayerData)
        hideModalOperation()
        activityLogSubmission("profile_setup", playerData?.msisdn);
        analyticEvent('profile', 'profile-update-success')
      }
    })
  }
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  return (
    <>
      <Modal show={showProfileEditModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>প্রোফাইল ইডিট</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <span className='inputSpan'>+88 <input type="text" maxLength={11}  id="mobileNumber" name="mobileNumber"
        placeholder="মোবাইল নাম্বার" /> </span> */}
          <div className='my-2 editNameDiv'>
            <label htmlFor="inputPlayerName" className='nameLabel'>Player Name</label> <br />
            <input type="text" id='inputPlayerName' className='inputNameField' placeholder='Your Name' value={nameValue} onChange={e => setNameValue(e.target.value)} />
          </div>
          <div className="selectionBtn">
            <button onClick={() => setActivePart('img')} className={`imgBtn ${(activePart === 'img') ? 'activeAvtImg' : ""}`}>Image</button>
            <button onClick={() => setActivePart('avatar')} className={`avtBtn ${(activePart === 'avatar') ? 'activeAvtImg' : ""}`}>Avatar</button>
          </div>
          {(activePart === 'avatar') && <div className='avatarDeck'>
            <div className={`avatarSingle ${(avatarId === 1 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(1) }}><img src={avatar1} className='avatarImg avatar1' alt="" srcSet="" /></div>
            <div className={`avatarSingle ${(avatarId === 2 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(2) }}><img src={avatar2} className='avatarImg avatar2' alt="" srcSet="" /></div>
            <div className={`avatarSingle ${(avatarId === 3 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(3) }}><img src={avatar3} className='avatarImg avatar3' alt="" srcSet="" /></div>
            <div className={`avatarSingle ${(avatarId === 4 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(4) }}><img src={avatar4} className='avatarImg avatar4' alt="" srcSet="" /></div>
            <div className={`avatarSingle ${(avatarId === 5 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(5) }}><img src={avatar5} className='avatarImg avatar5' alt="" srcSet="" /></div>
            <div className={`avatarSingle ${(avatarId === 6 ? 'activeAvatar' : '')}`} onClick={() => { setAvatarId(6) }}><img src={avatar6} className='avatarImg avatar6' alt="" srcSet="" /></div>
          </div>}

          {(activePart === 'img') && <div className='imgDeck'>
            <ImageUploading
              // multiple
              value={images}
              onChange={onChange}
              // maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      className='uploadImgBtn'
                    >
                      Upload Image
                    </button>
                  </div>
                  {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item mt-3">
                      <img src={image['data_url']} alt="" width="150" />
                      <div className="image-item__btn-wrapper">
                        {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                        {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn submitUpdate" onClick={updateOperation}>Update</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProfileEditModal