import axios from "axios";
import { baseUrl, portal } from "../App";

export default function activityLogSubmission(page_name, msisdn) {
  var userAgent = window.navigator.userAgent;
  var sourceUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const cidFromUrl = urlParams.get('qcid');
  var cid = cidFromUrl;
  // var cid = cidFromUrl || sessionStorage.getItem('cid');
  axios
    .post(`${baseUrl}/api/v1/activity_log/`, {
      msisdn: msisdn,
      page_name: page_name,
      source_url: sourceUrl,
      useragent: userAgent,
      cid:cid,
      company: portal
    })
    .then((response) => {
      // console.log(response);
    })
    .catch((error)=>{
      console.log(error)
    })
}
