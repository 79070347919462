import React, { useEffect, useState } from 'react'

const SingleOption = ({ props }) => {
  const [allOptions, setAllOptions] = useState([])
  const { currentQues, handleAnswerClick } = props;
  useEffect(() => {
    const list = [];
    list.push(
      <div className=" singleOptions op-1" style={{ backgroundColor: '#ffffff66',color:'#002EA4' ,border:'2px solid #002EA4', borderRight:'none' }} onClick={(e) => handleAnswerClick(e, 1)}>
        <div className="Option1 op">{currentQues?.option1}</div>
      </div>)
    list.push(<div className=" singleOptions op-2" style={{ backgroundColor: '#ffffff66' ,color:'#002EA4' ,border:'2px solid #002EA4', borderRight:'none' }} onClick={(e) => handleAnswerClick(e, 2)}>
      <div className="Option2 op">{currentQues?.option2}</div>
    </div>)
    if (currentQues?.options_nub >= 3) {
      list.push(<div className=" singleOptions op-3" style={{ backgroundColor: '#ffffff66' ,color:'#002EA4' ,border:'2px solid #002EA4', borderRight:'none' }} onClick={(e) => handleAnswerClick(e, 3)}>
        <div className="Option3 op">{currentQues?.option3}</div></div>)
    }
    if (currentQues?.options_nub >= 4) {
      list.push(<div className=" singleOptions op-4" style={{ backgroundColor: '#ffffff66' ,color:'#002EA4',border:'2px solid #002EA4' , borderRight:'none'}} onClick={(e) => handleAnswerClick(e, 4)}>
        <div className="Option4 op">{currentQues?.option4}</div>
      </div>) 
    }
    list.sort(() => Math.random() - 0.5);
    setAllOptions(list)
  }, [currentQues])

  return (
    <>
      {allOptions.map((item,index) => {
        return (
          <React.Fragment key={index}>{item}</React.Fragment>
        )
      })}
    </>
  )
}

export default SingleOption