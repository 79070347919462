import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import newLandingImg from "./assets/campLanding.jpg";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign3.module.css";
import LoginDivOnly from "../../components/LoginDivOnly/LoginDivOnly";
import TCModalTournament from "../../components/Modal/TCModalTournament/TCModalTournament";
import OtpModal from "../../components/Modal/OtpModal/OtpModal";
const Campaign3 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);
  const [showTCModalTournament, setShowTCModalTournament] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const cid = searchParams.get("qcid");
  const {playerData} = useContext(dataContext);
  let navigate = useNavigate();
  if (!cid) {
    navigate("../");
  }
  useEffect(() => {
    let campaignId = null;
    if(searchParams.get('qcid')){
      sessionStorage.setItem('cid', searchParams.get('qcid'));
      campaignId = searchParams.get('qcid');
    }
    setTimeout(() => {
      setShowLoginModalCampaign(true);
    }, 3000);

    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("campaign", msisdn);
    activityLogSubmission("campaign3", msisdn);
  }, []);

  return (
    <>
      <div className={`${styles.landingPage}`}>
        <img src={`https://ms.purplepatch.online/bkash-quiz-banner/camp-banner/camp3.jpg`} alt="" srcSet="" className={styles.landingImg} onClick={()=>{
          setShowLoginModalCampaign(true)
        }} />
        {showLoginModalCampaign && <LoginDivOnly
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
          setShowOtpModal={setShowOtpModal}
          showOtpModal={showOtpModal}
        ></LoginDivOnly>}
                <button 
          className={styles.tcButton} 
          onClick={() => setShowTCModalTournament(true)}
        >
          Terms & Conditions
        </button>
        <TCModalTournament
          showTCModalTournament={showTCModalTournament}
          setShowTCModalTournament={setShowTCModalTournament}
        ></TCModalTournament>

      {showOtpModal && (
        <OtpModal
          showOtpModal={showOtpModal}
          setShowOtpModal={setShowOtpModal}
          activeBottom={activeBottom}
        ></OtpModal>
      )}
      </div>
    </>
  );
};

export default Campaign3;
