import React, { useContext, useEffect, useState } from 'react'
import './QuizPage.css'
import backBtn from '../../assets/img/v2/back.png'
import { baseUrl, categoryEventId, dataContext, portal } from '../../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import QuestionBody from './QuestionBody/QuestionBody';
import BlockRound from './BlockRound/BlockRound';
import ResultModal from './ResultModal/ResultModal';
import mainImg from "../../assets/img/mainBg.png";
import bplImg from "../../assets/img/background/quizpageMainBg.jpg";
import eidImg from "../../assets/img/background/eid.png";
import boishakhImg from "../../assets/img/background/boishakh.png";
import weekImg from "../../assets/img/background/week.jpg";
import ramadanImg from "../../assets/img/background/ramadan.png";
import summerImg from "./bgimage/summerbg.png";
import kurbaniImg from "./bgimage/kurbani.jpg";
import worldcupImg from "./bgimage/worldcup.png";
import copaImg from "./bgimage/copa.png";
import rainImg from "./bgimage/rain.png";
import winterImg from "./bgimage/winter.png";
import independenceImg from "../../assets/img/background/independence.png";
import QuestionBodyBPL from './QuestionBodyBPL/QuestionBodyBPL';
import QuestionBodyEid from './QuestionBodyEid/QuestionBodyEid';
import QuestionBodyBoishakh from './QuestionBodyBoishakh/QuestionBodyBoishakh';
import QuestionBodyWeekly from './QuestionBodyWeekly/QuestionBodyWeekly';
import QuestionBodyInd from './QuestionBodyInd/QuestionBodyInd';
import QuestionBodySummer from './QuestionBodySummer/QuestionBodySummer';
import QuestionBodyRamadan from './QuestionBodyRamadan/QuestionBodyRamadan';
import { pushCleverTapEvent } from '../../helper/clevertapFunction';
const QuizPage = () => {
  const navigate = useNavigate()
  const {token, setToken, playerData, setPlayerData} = useContext(dataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = parseInt(searchParams.get("eventId"))
  const [eventDetails, setEventDetails] = useState({})
  const [showQuestions, setShowQuestions] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showResultModal, setShowResultModal] = useState(false)
  const [finalScore, setFinalScore] = useState(0)
  const [playerRound, setPlayerRound] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [roundChecked, setRoundChecked] = useState(false)
  const [fetchRequest, setFetchRequest] = useState(false)

  useEffect(() => {
    if (token && eventId) {
      axios.get(`${baseUrl}/api/clients/participant_profiles/?portal=${portal}`, {
        headers: { Authorization: `Token ${token}` }
      })
        .then(response => {
          setPlayerData(response.data[0])
          let data = response.data[0];
          // console.log(data);
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          if(categoryEventId.includes(eventId)){
            axios.get(`${baseUrl}/api/rules/?portal=${portal}&is_event=false`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
            })
          }
          else if (data?.isSubscribe && eventId===34) {
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
          })
          }else if (data?.isSubscribeTournament && eventId !==34) {
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
          })
          }else{navigate('../')          }
        }).catch(res => {
          // console.log(res.response.status === 401)
          localStorage.setItem("AuthToken", "");
          localStorage.setItem("user_msisdn", "");
          setPlayerData({});
          // setShowLoginModal(true);
          setToken(null);
          navigate('../')
        });
    }else{
      navigate('../')
    }

  }, [])
  useEffect(() => {
    if (isLoadingEnd) {
      let playerLeaderboardArr = playerData?.event_history;
      let playerRoundNum = 0;
      let playerLeaderboard = []
      if(playerLeaderboardArr){playerLeaderboard = playerLeaderboardArr.filter((a)=>{if(a.event_id==eventDetails.id){return a}});}
      playerRoundNum = (playerLeaderboard.length)?playerLeaderboard[0]?.round_number__max : 0;
      let eventRound = eventDetails?.round
      setPlayerRound(playerRoundNum)
      // console.log(playerRoundNum,eventRound,eventDetails)
      if (playerRoundNum < eventRound) {
        setShowQuestions(true)
        if(eventId == 34){pushCleverTapEvent('StartPlay-QuizPratidin')}
        else if(eventId == 75){pushCleverTapEvent('StartPlay-SportsQuiz')}
        else if(eventId == 77){pushCleverTapEvent('StartPlay-WinterQuiz')}
        sessionStorage.setItem('played_eventId', eventId);
      } else {
        setShowBlockModal(true)
      }
    }
  }, [isLoadingEnd])
  // CustomEvents Background and Element
  let backgroundImg;
  if(eventId === 32){backgroundImg = bplImg}
  // else if(eventId === 36){backgroundImg = weekImg}
  else if(eventId === 37){backgroundImg = independenceImg}
  // else if(eventId === 38){backgroundImg = ramadanImg}
  else if(eventId === 39){backgroundImg = bplImg}
  else if(eventId === 53){backgroundImg = worldcupImg}
  else if(eventId === 40){backgroundImg = boishakhImg}
  else if(eventId === 54){backgroundImg = summerImg}
  else if(eventId === 55){backgroundImg = kurbaniImg}
  else if(eventId === 61){backgroundImg = copaImg}
  else if(eventId === 66){backgroundImg = rainImg}
  else if(eventId === 75){backgroundImg = bplImg}
  else if(eventId === 77){backgroundImg = winterImg}
  else{backgroundImg = mainImg}
  let customEvent = [32,37,38,39,40,53,54,55,61,77];

  return (
    <div className='quizPageBody' style={{ backgroundImage: `url(${backgroundImg})` }}>
      {/* {(showQuestions && (eventId === 53)) && (<QuestionBodyBPL eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyBPL>)} */}
      {(showQuestions && (eventId === 54)) && (<QuestionBodySummer eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodySummer>)}
      {(showQuestions && (eventId === 61)) && (<QuestionBodyInd eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyInd>)}
      {(showQuestions && (eventId === 53)) && (<QuestionBodyRamadan eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyRamadan>)}
      {(showQuestions && (eventId === 55)) && (<QuestionBodyEid eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyEid>)}
      {(showQuestions && (eventId === 77)) && (<QuestionBodyBoishakh eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyBoishakh>)}
      {/* {(showQuestions && (eventId === 36)) && (<QuestionBodyWeekly eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyWeekly>)} */}

      {(showQuestions && (!customEvent.includes(eventId))) && (<QuestionBody eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBody>)}
      {showBlockModal && (<BlockRound eventDetails={eventDetails} showBlockModal={showBlockModal} setShowBlockModal={setShowBlockModal}></BlockRound>)}
      {showResultModal && (<ResultModal eventDetails={eventDetails} showResultModal={showResultModal} setShowResultModal={setShowResultModal} finalScore={finalScore}></ResultModal>)}

    </div>
  )
}

export default QuizPage