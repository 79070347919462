import React from 'react'
import logo from '../../assets/logo/logo192.png';
import './OpeningLoader.css';

const OpeningLoader = () => {
  return (
    <div className="opening-loader">
      <img
        src={logo}
        alt="Logo"
        className="logo-opening-loader"
      />
      <p className="opening-loader-text">Loading...</p>
    </div>
  )
}

export default OpeningLoader